import { useRef, ChangeEvent, useState, useEffect } from "react";
import ReactGA from "react-ga4";
import moment from "moment";
import { getBase64 } from "../../utils/file";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import camera from "../../images/camera.svg";
import sync from "../../images/sync.svg";
import pdf from "../../images/pdf.png";

type ButtonListPictureProps = {
  title: string;
  value: string[];
  onChange: (base64: string[], formatted: string) => void;
  required?: boolean;
  isLabelOptionnal?: boolean;
};

interface ImageData {
  base64: string[];
  formatted: string;
}

function ButtonListPicture({
  title,
  onChange,
  value,
  required,
  isLabelOptionnal = true,
}: ButtonListPictureProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentImage, setCurrentImage] = useState<ImageData | null>(null);

  useEffect(() => {
    if (currentImage) {
      onChange(currentImage.base64, currentImage.formatted);
    }
  }, [currentImage]);

  return (
    <div className="button-picture-container">
      {currentImage ? (
        <>
          <div onClick={() => setCurrentImage(null)}>
            <img className="button-picture-icon" alt="trash" src={trash} />
          </div>
          <div className="button-picture-viewer">
            {currentImage.base64.map((base64, key) => (
              <img
                key={key}
                height={"36"}
                width={"36"}
                src={
                  base64.includes("image/png") ||
                  base64.includes("image/jpg") ||
                  base64.includes("image/jpeg")
                    ? base64
                    : pdf
                }
                alt={title}
              />
            ))}
          </div>
          <div onClick={() => inputRef.current?.click()}>
            <img className="button-picture-icon-right" alt="sync" src={sync} />
          </div>
        </>
      ) : null}
      <button
        className="button-picture"
        onClick={() => {
          ReactGA.event({
            category: "click_list_picture",
            action: "open_camera",
            value: 0,
          });
          inputRef.current?.click();
        }}
      >
        <span>
          {title}{" "}{currentImage && currentImage.base64.length ? " - Ajouter un fichier supplémentaire " : ""}
          {required ? "(Obligatoire)" : isLabelOptionnal ? "(Facultatif)" : ""}
        </span>
        <img alt="camera" src={currentImage ? check : camera} />
      </button>
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*,audio/*,.doc,.csv,.pdf"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            const time = file.lastModified
              ? moment(file.lastModified)
              : moment();

            const formatted = time.format("DD/MM/YYYY HH:mm:ss");

            getBase64(file)
              .then((result: any) => {
                ReactGA.event({
                  category: "click_list_picture",
                  action: "close_camera_true",
                  value: 1,
                });
                setCurrentImage({
                  base64: currentImage
                    ? [...currentImage?.base64, result.base64]
                    : [result.base64],
                  formatted,
                });
              })
              .catch(() => {
                ReactGA.event({
                  category: "click_list_picture",
                  action: "close_camera_error",
                  value: 3,
                });
              });
          } else {
            ReactGA.event({
              category: "click_list_picture",
              action: "close_camera_false",
              value: 2,
            });
          }
        }}
      />
    </div>
  );
}

export default ButtonListPicture;
