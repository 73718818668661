import { DataType, FinalData } from "../types/types";

const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      const maxSize = 1300;

      if (
        reader.result &&
        typeof reader.result === "string" &&
        (reader.result.includes("image/png") ||
          reader.result.includes("image/jpg") ||
          reader.result.includes("image/jpeg"))
      ) {
        let image = new Image();
        image.src = reader.result;
        image.onload = () => {
          let finalWidth;
          let finalHeight;

          let initialWidth = image.width;
          let initialHeight = image.height;
          if (initialWidth > initialHeight) {
            if (initialWidth > maxSize) {
              finalWidth = maxSize;
              finalHeight = (finalWidth * initialHeight) / initialWidth;
            } else {
              finalWidth = initialWidth;
              finalHeight = initialHeight;
            }
          } else {
            if (initialHeight > maxSize) {
              finalHeight = maxSize;
              finalWidth = (finalHeight * initialWidth) / initialHeight;
            } else {
              finalWidth = initialWidth;
              finalHeight = initialHeight;
            }
          }

          if (finalWidth !== initialWidth) {
            const canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            canvas.width = finalWidth;
            canvas.height = finalHeight;
            if (ctx) {
              ctx.drawImage(image, 0, 0, finalWidth, finalHeight);
              resolve({
                base64: canvas.toDataURL("image/jpeg", 0.86),
                type: file.type,
              });
            } else {
              resolve({ base64: reader.result, type: file.type });
            }
          } else {
            resolve({ base64: reader.result, type: file.type });
          }
        };
        image.onerror = () => {
          resolve({ base64: reader.result, type: file.type });
        };
      } else {
        resolve({ base64: reader.result, type: file.type });
      }
    };

    reader.onerror = () => {
      reject();
    };
  });
};

const convertData = (data: DataType) => {
  let finalData: Array<FinalData> = [];

  const getType = (type: string) => {
    if (type === "Paragraph") {
      return "paragraph";
    } else if (type === "ButtonListPicture") {
      return "listPicture";
    } else if (type === "ButtonPicture") {
      return "picture";
    } else if (type === "ButtonPictureMask") {
      return "picture";
    } else if (type === "ButtonDocument") {
      return "document";
    } else if (type === "Textarea") {
      return "text";
    } else if (type === "YesNo") {
      return "radio";
    } else if (type === "Checkbox") {
      return "checkbox";
    } else if (type === "InputText") {
      return "text";
    } else if (type === "ButtonSignature") {
      return "signature";
    } else {
      return "";
    }
  };

  const getData = (type: string, value?: string, checked?: boolean) => {
    if (type === "Checkbox") {
      return checked;
    } else if (type === "YesNo") {
      if (checked === true) {
        return "1";
      } else if (checked === false) {
        return "2";
      } else {
        return "";
      }
    } else {
      return value;
    }
  };

  data.body.forEach((page) => {
    page.elements.forEach((element) => {
      let line: FinalData = {
        type: getType(element.type),
        name: element.text || element.title || element.placeholder || "",
        pdf: 0,
        inpdf: 1,
        data: "",
        fileType: "",
        time: element.time || "",
        type_document_true: element.type_true,
        type_document_false: element.type_false,
        special_file_type: element.isSpecialFileType,
      };

      line.data = getData(element.type, element.value, element.checked) || "";

      const lines =
        element.type === "ButtonListPicture" &&
        Array.isArray(element.valueArray)
          ? element.valueArray.map((list: string) => ({
              ...line,
              data: list,
            }))
          : [line];

      finalData.push(...lines);
    });
  });

  return finalData;
};

export { getBase64, convertData };
